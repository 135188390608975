
import { defineComponent } from "vue";
import { Avatar3 } from "@/uikit";
import { format } from "date-fns";

export default defineComponent({
  props: ["certificat"],
  components: { Avatar3 },
  setup(props) {
    return { format };
  }
});
