
import { defineComponent, ref } from "vue";
import { useRoute } from "@/helpers";
import CertificatesItem from "./components/certificates-item.vue";
import { ERoutesName } from "@/router";

export default defineComponent({
  components: { CertificatesItem },
  setup() {
    const { push } = useRoute();
    const certificates = ref<any[]>([]);
    const _certificates = localStorage.getItem("certificates");
    if (_certificates) certificates.value = JSON.parse(_certificates);

    const handleDetail = (id: string) =>
      push({ name: ERoutesName.MORE_INFO_DETAIL, params: { id } });
    return { certificates, handleDetail };
  }
});
